
/*
枚举类型
*/

// 接口Code状态
export enum APIResponseCodeType {
    'success'   = 1,    // 成功
    'error'     = 0,    // 失败
    'redirect'  = -1,   // 重定向
    'page'      = 2,    // 跳转页面
}

// 页面模式
export enum PageMode {
    'ADD'   = 'add',    // 添加
    'EDIT'  = 'edit',   // 编辑
}

// 订单管理类型
export enum OrderType {
    'all_count', //全部
    'pay_count', //待支付
    'delivery_count', //待收货
    'receive_count', //待发货
    'finish_count', //已完成
    'close_count' //已关闭
}